import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: '840px',
    width: '100%',
    minHeight: '60%',
    maxHeight: '80%',
    backgroundColor: '#F2F2F7',
    padding: '25px 20px 20px 20px'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '24px',
    color: theme.palette.primary.main
  },
  dialogContent: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    padding: '0'
  },
  closeButton: {
    fontSize: '25px',
    color: theme.palette.grey[500],
    cursor: 'pointer'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  errorContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  noDataSVG: {
    maxWidth: '100%',
    height: '80%'
  },
  errorTitle: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#32394E'
  },
  errorSubtitle: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginBottom: '16px'
  },
  reloadButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',
    padding: '5px 30px',
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  pdfWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    '& .react-pdf__Page__canvas': {
      width: '100% !important',
      height: 'auto !important'
    }
  },
  editButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: '0',
    width: '100%',
    marginTop: '20px'
  },
  editButton: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: 'white'
    }
  }
}));
