import React from 'react';
import { useFormContext } from 'react-hook-form';

import i18n from '~/I18n';

import { ClearFiltersButton } from '../ClearFIltersButton';
import Selection from './Selection';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const Filters = ({
  handleSelectFilters,
  clearFilters,
  states,
  regionalGroups,
  cities,
  disableClearFilters
}) => {
  const { watch } = useFormContext();

  return (
    <Box
      style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}
      onScroll={(e) => {
        e.stopPropagation();
      }}
    >
      <Selection
        objects={states}
        handleOnChange={(state) => handleSelectFilters(state, 'state')}
        selectedValue={watch('state')}
        defaultValue={'Estado'}
      />
      <Selection
        objects={regionalGroups}
        handleOnChange={(regionalGroup) =>
          handleSelectFilters(regionalGroup, 'regionalGroup')
        }
        selectedValue={watch('regionalGroup')}
        defaultValue={'Grupo Regional'}
      />
      <Selection
        objects={cities}
        handleOnChange={(city) => handleSelectFilters(city, 'city')}
        selectedValue={watch('city')}
        defaultValue={i18n.t('City')}
      />
      <ClearFiltersButton
        clearFilters={clearFilters}
        disableClearFilters={disableClearFilters}
      />
    </Box>
  );
};

Filters.propTypes = {
  handleSelectFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  regionalGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableClearFilters: PropTypes.bool
};
