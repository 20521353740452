// Imports necessary for API requests and URL manipulation
import api from '~/services/api';

// Base URL for course-related API endpoints
const baseURL = '/api/enrollment_limits';
const courseURL = '/api/courses';

export const createEnrollmentLimit = async (courseID, body) => {
  const { data } = await api.post(
    `${courseURL}/${courseID}/enrollment_limits`,
    body
  );
  return data;
};

export const updateEnrollmentLimit = async (userLimitID, body) => {
  const { data } = await api.put(`${baseURL}/${userLimitID}`, body);
  return data;
};

export const removeUserLimit = async (userLimitID) => {
  const { data } = await api.delete(`${baseURL}/${userLimitID}`);
  return data;
};

export default {
  createEnrollmentLimit,
  updateEnrollmentLimit,
  removeUserLimit
};
