import React from 'react';

import CertificateInfo from './CertificateInfo';
import MainInfo from './MainInfo';
import NotificationInfo from './NotificationInfo';
import PagseguroInfo from './PagseguroInfo';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';

export default function CourseForm() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.content}>
      <MainInfo />
      {/* <div style={{ marginBottom: '10px' }}>
      {watch('id') && (
        <div style={{ marginBottom: '10px' }}>
          <EnrollmentLinkToggle
            structureID={watch('id')}
            tooltipMessage={tooltipEnrollmentLinkMessage}
            getEnrollmentLink={getEnrollmentLink}
            createEnrollmentLink={createEnrollmentLink}
          />
        </div>
      )}
      <div style={{ marginBottom: '10px' }}>
        <EnrollmentLimit />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <CertificateInfo />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <PagseguroInfo />
      </div>
      {/* <NotificationInfo /> */}
    </div>
  );
}
