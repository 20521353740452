import React from 'react';

import Text from '~/components/atoms/Text';

import { useValidateCertificateContext } from '../ValidateCertificateContext';
import { Box, useTheme } from '@material-ui/core';

export default function CertificateInfo() {
  const theme = useTheme();
  const { isValid, certificate, loading } = useValidateCertificateContext();

  const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric'
  });

  const formatDate = (date) => {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return dateFormatter.format(date);
  };

  return (
    !loading &&
    isValid && (
      <Box>
        <Text
          variant="xl"
          style={{
            letterSpacing: 1,
            fontWeight: 500,
            color: theme.palette.activeText.main
          }}
        >
          Informações do documento
        </Text>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
          <Box>
            <Text variant="lg" style={{ color: theme.palette.text.main }}>
              {certificate.structure.name}
            </Text>
            <Text variant="lg" style={{ color: theme.palette.activeText.main }}>
              {`Carga horária: ${certificate.structure.workload} horas`}
            </Text>
            <Text variant="lg" style={{ color: theme.palette.activeText.main }}>
              {`Número de registro: ${certificate.code}`}
            </Text>
            <Text variant="lg" style={{ color: theme.palette.activeText.main }}>
              {`Data de emissão: ${formatDate(certificate.created_at)}`}
            </Text>
          </Box>
        </Box>
      </Box>
    )
  );
}
