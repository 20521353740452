import React from 'react';

import Text from '~/components/atoms/Text';

import { useTheme } from '@material-ui/core';

export default function Title() {
  const theme = useTheme();
  return (
    <Text
      variant="header"
      style={{
        letterSpacing: 1,
        fontWeight: 500,
        color: theme.palette.activeText.main
      }}
    >
      Verificar autenticidade de documento
    </Text>
  );
}
