import React from 'react';
import { useHistory } from 'react-router-dom';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';

import { useValidateCertificateContext } from '../ValidateCertificateContext';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';

export default function Verify() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const {
    code,
    isValid,
    handleVerify,
    loading
  } = useValidateCertificateContext();

  return (
    <Box className={classes.root}>
      <Box>
        <Text variant="lg" style={{ color: theme.palette.activeText.main }}>
          Informe o número de registro do documento para verificar sua
          autenticidade
        </Text>
        <Input
          style={{ width: '250px' }}
          defaultValue={code}
          onChange={(e) =>
            history.push(`/validate-certificate/${e.target.value}`)
          }
          placeholder="Número de registro"
        ></Input>
      </Box>
      <Box style={{ display: 'flex', gap: '12px' }}>
        <ButtonSquare
          width={100}
          height={35}
          backgroundColor={theme.palette.secondary.main}
          onClick={handleVerify}
        >
          <Text className={classes.verifyButtonText}>Verificar</Text>
        </ButtonSquare>

        <ButtonSquare
          width={100}
          height={35}
          backgroundColor="white"
          border={`1px solid ${theme.palette.secondary.main}`}
          onClick={() => history.push('/dashboard')}
        >
          <Text className={classes.cancelButtonText}>Cancelar</Text>
        </ButtonSquare>
      </Box>

      {!loading && (
        <Box
          style={{
            display: 'flex',
            padding: '16px',
            justifyContent: 'center',
            backgroundColor: isValid
              ? theme.palette.success.main
              : theme.palette.error.main
          }}
        >
          <Text className={classes.verifyButtonText}>
            {isValid
              ? 'o documento com o número de registro informado é autêntico'
              : 'Não foi encontrado documento de conclusão com o número de registro informado.'}
          </Text>
        </Box>
      )}
    </Box>
  );
}
