import React from 'react';

import Page from '~/components/templates/Page';

import CertificateInfo from './CertificateInfo';
import DownloadButton from './DownloadButton';
import { useStyles } from './styles';
import Title from './Title';
import UserInfo from './UserInfo';
import { ValidateCertificateProvider } from './ValidateCertificateContext';
import Verify from './Verify';
import { Box } from '@material-ui/core';

export default function ValidateCertificate() {
  const classes = useStyles();
  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <ValidateCertificateProvider>
            <Title />
            <Verify />
            <UserInfo />
            <CertificateInfo />
            <DownloadButton />
          </ValidateCertificateProvider>
        </Box>
      </Box>
    </Page>
  );
}
