import React from 'react';

import { createCertificateConfiguration } from '~/services/Space';

import CertificateConfiguration from '../../molecules/CertificateConfiguration';
import { useStyles } from './styles';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function SpaceAdvancedOptions({ space }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <CertificateConfiguration
          structure={space}
          createCertificateConfiguration={createCertificateConfiguration}
        />
        <Divider className={classes.divider} />
      </Box>
    </Box>
  );
}

SpaceAdvancedOptions.propTypes = {
  space: PropTypes.object.isRequired
};
