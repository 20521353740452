import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'scroll',
    width: '100vw'
  },
  content: {
    paddingTop: '60px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '0 20%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px'
    }
  }
}));
