import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { ReactComponent as NoData } from '~/assets/images/no-data.svg';
import certificateService from '~/services/Certificate';

import { useStyles } from './styles';
import { Box, Typography, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

// Set the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PreViewCertificate({
  openPreview,
  setOpenPreview,
  setOpenEditButton,
  configurationID
}) {
  const classes = useStyles();
  const [certificate, setCertificate] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [renderEditButton, setRenderEditButton] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [reload, setReload] = useState(0);

  const handleClose = () => {
    setErrorOcurred(false);
    setCertificate(null);
    setRenderEditButton(false);
    setOpenPreview(false);
  };

  useEffect(() => {
    if (openPreview) {
      setIsFetching(true);
      certificateService
        .previewCertificate(configurationID)
        .then((response) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setCertificate(reader.result);
            setIsFetching(false);
          };
          reader.readAsDataURL(response);
        })
        .catch((error) => {
          setIsFetching(false);
          setRenderEditButton(true);
          setErrorOcurred(true);
        });
    }
  }, [openPreview, reload]);

  const onPageLoadSuccess = () => {
    setRenderEditButton(true);
  };

  const onPageLoadError = () => {
    setErrorOcurred(true);
  };

  const onReload = () => {
    setReload(reload + 1);
    setErrorOcurred(false);
  };

  return (
    <Dialog
      open={openPreview}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <Box className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          Pré-visualização
        </Typography>
        <CloseIcon className={classes.closeButton} onClick={handleClose} />
      </Box>

      <DialogContent className={classes.dialogContent}>
        {!certificate && isFetching && (
          <CircularProgress className={classes.loader} />
        )}

        {errorOcurred && (
          <Box className={classes.errorContent}>
            <NoData className={classes.noDataSVG} />
            <Typography className={classes.errorTitle}>
              Ocorreu um erro inesperado!
            </Typography>
            <Typography className={classes.errorSubtitle}>
              Tente novamente mais tarde
            </Typography>
            <Button className={classes.reloadButton} onClick={onReload}>
              Recarregar
            </Button>
          </Box>
        )}

        {certificate && !isFetching && !errorOcurred && (
          <Box className={classes.pdfWrapper}>
            <Document
              file={certificate}
              loading={<CircularProgress />}
              onLoadSuccess={onPageLoadSuccess}
              onLoadError={onPageLoadError}
            >
              <Page pageNumber={1} onRenderSuccess={onPageLoadSuccess} />
            </Document>
          </Box>
        )}

        {renderEditButton && (
          <Box className={classes.editButtonWrapper}>
            <Button
              onClick={() => {
                handleClose();
                setOpenEditButton(true);
              }}
              className={classes.editButton}
              disabled={errorOcurred}
            >
              Editar Certificado
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
