import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import UnlinkDialog from '~/components-refactor/molecules/UnlinkDialog';
import Text from '~/components/atoms/Text';
import DashboardBreadcrumbs from '~/components/molecules/DashboardBreadcrumbs';
import PopUpMenu from '~/components/molecules/PopUpMenu';
import StructureCreationPopUp from '~/components/molecules/StructureCreationPopUp';
import ShareContent from '~/components/organisms/ShareContents/ShareContentCourses';
import TitleWrapHelper from '~/components/organisms/TitleWrapHelper';
import AppbarPages from '~/components/templates/AppbarPages';
import Members from '~/components/templates/Members';
import i18n from '~/I18n';
import api from '~/services/api';
import { createCertificate, getCertificate } from '~/services/Certificate';
import { getEnrollments, searchMembers } from '~/services/course';
import { unlink } from '~/services/mirroring/course';
import { update as updateRole } from '~/services/user-course-association';
import settings from '~settings';

import PageUnauthorized from '../PageUnauthorized';
import Classification from './Classification';
import Content from './Content';
import PaperEnvironmentInformation from './PaperEnvironmentInformation';
import Sidebar from './Sidebar';
import { useStyles, ShimmerText } from './styles';
import { Button, useTheme } from '@material-ui/core';
import { Box, Modal } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ShareIcon from '@material-ui/icons/Share';
import { useSnackbar } from 'notistack';

export default function Course() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [contentIsSelected, setContentIsSelected] = useState(true);
  const [
    contentClassificationIsSelected,
    setContentClassificationIsSelected
  ] = useState(true);
  const [showEnvironmentInformation, setShowEnvironmentInformation] = useState(
    false
  );
  const [environmentDetails, setEnvironmentDetails] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);
  const [course, setCourse] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [generateCertificate, setGenerateCertificate] = useState(false);
  const [certificateID, setCertificateID] = useState(null);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const { id, courseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getCourse() {
      try {
        const { data } = await api.get(
          `api/environments/${id}/courses/${courseId}`
        );
        setCourse(data);
        setCourseDetails(data);
        setEnvironmentDetails(data.environment);
        handleCertificateButton(data);
        setCertificateID(data.certificate_id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    }

    const getSpaces = async () => {
      await api
        .get(`api/courses/${courseId}/spaces`)
        .then((res) => setSpaces(res.data))
        .catch(console.log);
    };

    getCourse();
    getSpaces();
  }, [id]);

  const handleCertificateButton = (course) => {
    if (course.user_completion === undefined) return;
    const graduated =
      parseFloat(course.user_completion) >=
      parseFloat(course.minimum_rate_for_completion);

    if (
      (graduated && course.certificate_configuration_id) ||
      (graduated && course.certificate_id)
    ) {
      setGenerateCertificate(true);
    }
  };

  const downloadCertificate = async () => {
    try {
      let certificateIdToUse = certificateID;

      if (!certificateID) {
        const createResponse = await createCertificate(
          course.certificate_configuration_id
        );
        certificateIdToUse = createResponse.id;
        setCertificateID(certificateIdToUse);
      }

      const blob = await getCertificate(certificateIdToUse);

      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.target = '_blank';
      link.download = `certificado_${course.id}.pdf`;

      link.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      enqueueSnackbar('Erro ao gerar certificado.', {
        variant: 'error'
      });
    }
  };

  const linkList = [
    {
      Icon: AddIcon,
      component: (
        <StructureCreationPopUp
          text={`Nova ${i18n.t(`StructuresName.Space.SingularUpper`)}`}
          structure={{
            name: 'space',
            parentStructure: {
              name: 'Course',
              id: courseId
            }
          }}
          structureName={i18n.t('StructuresName.Space.SingularUpper')}
          redirectLink={`/dashboard/environments/${id}/courses/${courseId}/spaces/new`}
        />
      ),
      style: {
        minWidth: 150,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        justifyContent: 'flex-start'
      }
    },
    {
      link: `${course.id}/edit`,
      Icon: EditIcon,
      text: i18n.t('EditCourse'),
      disabled: false
    },
    courseDetails?.can_unlink && {
      Icon: LinkOffIcon,
      text: 'Desvincular espelho',
      action: (e) => {
        e.preventDefault();
        setShowUnlinkModal(true);
      },
      disabled: false
    },
    {
      action: (e) => {
        e.preventDefault();
        setOpen(true);
      },
      Icon: ShareIcon,
      text: `Compartilhar ${i18n.t('StructuresName.Course.SingularLower')}`,
      disabled: false
    }
  ].filter(Boolean);

  const image =
    courseDetails &&
    courseDetails?.thumbnails &&
    courseDetails?.thumbnails.length === 1
      ? courseDetails?.thumbnails[0][1][0].href
      : courseDetails?.thumbnails &&
        courseDetails?.thumbnails.length > 1 &&
        courseDetails?.thumbnails[1][0] !== 'missing'
      ? courseDetails?.thumbnails[1][1][0].href
      : courseDetails?.thumbnails[0][1][0].href;

  useEffect(() => {
    if (courseDetails) {
      document.title = `${theme.title} | ${course.name}`;
    } else {
      document.title = theme.title;
    }
  }, [courseDetails]);

  return (
    <>
      {error ? (
        <PageUnauthorized />
      ) : (
        <AppbarPages theme={theme}>
          <Box className={classes.content}>
            {environmentDetails && !loading ? (
              <>
                <Box className={classes.sidebar}>
                  <Box
                    style={{
                      marginTop: 32
                    }}
                  >
                    <img src={image && `${settings.baseUrl}${image}`} />
                  </Box>

                  <Sidebar
                    contentIsSelected={contentIsSelected}
                    contentClassificationIsSelected={
                      contentClassificationIsSelected
                    }
                    setContentClassificationIsSelected={
                      setContentClassificationIsSelected
                    }
                    setContentIsSelected={setContentIsSelected}
                  />
                </Box>
                <Box className={classes.infos}>
                  <TitleWrapHelper>
                    <Box className={classes.infosHeader}>
                      <DashboardBreadcrumbs
                        links={[
                          {
                            name: i18n.t('MyEnvironments'),
                            path: '/dashboard/environments'
                          },
                          {
                            name: `${environmentDetails?.name}`,
                            path: `/dashboard/environments/${environmentDetails?.id}`
                          },
                          { name: `${course.name}`, path: '#', last: true }
                        ]}
                      />
                      <Text
                        variant="xl"
                        style={{
                          letterSpacing: 1,
                          fontWeight: 500,
                          color: theme.palette.activeText.main
                        }}
                      >
                        {course.name}
                      </Text>
                      <Box className={classes.ambientInformationText}>
                        <InfoIcon
                          style={{ fontSize: 20 }}
                          className={classes.infoIcon}
                        />
                        <Text
                          color="accent"
                          variant="largeTextHover"
                          onClick={() => setShowEnvironmentInformation(true)}
                        >
                          {i18n.t('CourseInformation')}
                        </Text>
                      </Box>
                      {showEnvironmentInformation && (
                        <PaperEnvironmentInformation
                          setShowEnvironmentInformation={
                            setShowEnvironmentInformation
                          }
                          environment={courseDetails}
                        />
                      )}

                      <Box>
                        <Box className={classes.wrapperManageButton}>
                          {course.can_manage && (
                            <PopUpMenu
                              text={i18n.t('ManageCourse')}
                              type="settings"
                              showText
                              linkList={linkList}
                              isCourse
                            />
                          )}
                        </Box>
                        <Box className={classes.certificateInfo}>
                          {generateCertificate && (
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              className={classes.certificateButton}
                              onClick={downloadCertificate}
                            >
                              {`${i18n.t('GenerateCertificate')}`}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TitleWrapHelper>
                  <Box className={classes.infosContent}>
                    <Box className={classes.tabBar}>
                      <Sidebar
                        contentIsSelected={contentIsSelected}
                        setContentIsSelected={setContentIsSelected}
                        contentClassificationIsSelected={
                          contentClassificationIsSelected
                        }
                        setContentClassificationIsSelected={
                          setContentClassificationIsSelected
                        }
                      />
                    </Box>
                    {contentIsSelected && (
                      <>
                        {!error && (
                          <Content
                            spaces={spaces}
                            courseId={courseId}
                            environmentId={id}
                            environmentName={environmentDetails?.name}
                          />
                        )}
                      </>
                    )}

                    {!contentIsSelected && !contentClassificationIsSelected && (
                      <Members
                        getEnrollments={getEnrollments}
                        structureID={courseId}
                        updateRole={updateRole}
                        searchMembers={searchMembers}
                      />
                    )}
                    {contentClassificationIsSelected && !contentIsSelected && (
                      <Classification courseId={courseId} environmentId={id} />
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box className={classes.sidebar} />
                <Box className={classes.infos}>
                  <Box className={classes.infosHeader}>
                    <ShimmerText height={25} width="40%" />
                    <ShimmerText height={50} width="60%" />
                    <ShimmerText height={20} width="40%" />
                    <ShimmerText height={50} width="20%" />
                    <ShimmerText height={50} width="20%" />
                    <ShimmerText height={50} width="20%" />
                  </Box>
                </Box>
              </>
            )}
            <Modal
              open={open && id}
              onClose={handleClose}
              className={classes.modal}
            >
              <div>
                <ShareContent courseID={courseId} />
              </div>
            </Modal>
          </Box>
        </AppbarPages>
      )}
      <UnlinkDialog
        open={showUnlinkModal}
        handleClose={() => setShowUnlinkModal(false)}
        unlink={unlink}
        structureID={courseDetails?.id}
        callback={() => window.location.reload()}
      />
    </>
  );
}
