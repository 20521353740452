import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  picker: {
    width: '100%',
    '& input': {
      fontSize: 12,
      padding: 12
    },
  },
  label: {
    marginTop: 10,
    marginBottom: 5,
  },
  icon: {
    fontSize: 31,
    color: theme.palette.action.active,
    cursor: 'pointer',
    borderRadius: '50%',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));
