import * as yup from 'yup';

export const lectureValidations = yup
  .object()
  .shape({
    name: yup.string().required('Este campo é obrigatório.'),
    lectureable: yup.object({
      type: yup.string(),
      videocall: yup.object({
        kind: yup.string(),
        google_meet: yup.object().when('kind', {
          is: 'google_meet',
          then: yup.object().shape({
            event_start: yup
              .date()
              .nullable()
              .test(
                'is-null',
                'Adicione uma data inicial para o evento.', // Mensagem de erro quando o valor for null
                (value) => value !== null // Testa se o valor não é null
              ),
            event_end: yup
              .date()
              .nullable()
              .test(
                'is-null',
                'Adicione uma data final para o evento.', // Mensagem de erro quando o valor for null
                (value) => value !== null // Testa se o valor não é null
              )
              .when('event_start', (event_start) => {
                if (event_start)
                  return yup
                    .date()
                    .nullable()
                    .test(
                      'is-null',
                      'Adicione uma data final para o evento.', // Mensagem de erro quando o valor for null
                      (value) => value !== null // Testa se o valor não é null
                    )
                    .min(
                      event_start,
                      'A data de fechamento deve ser após a de abertura.'
                    );
              })
          })
        })
      }),
      youtube: yup.object().when('type', {
        is: 'youtube',
        then: yup.object().shape({
          external_resource_url: yup
            .string()
            .test('valid_url', 'Formato Inválido', (url) => {
              if (url) {
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=|live\/)([^#\&\?]*).*/;
                var match = url.match(regExp);

                if (match && match[2].length == 11) return true;
                else return false;
              }
            })
        })
      }),
      clone: yup.object().when('type', {
        is: 'clone',
        then: yup.object().shape({
          lecture_id: yup.string().required('Este campo é obrigatório.')
        })
      }),
      exercise: yup.object().when('type', {
        is: 'exercise',
        then: yup.object().shape({
          questions: yup
            .array()
            .min(1, 'É necessário criar pelo menos uma questão.')
            .required('É necessário criar pelo menos uma questão.')
        })
      }),
      video: yup.object().when('type', {
        is: 'video',
        then: yup.object().shape({
          vimeoOption: yup.boolean(),
          videoID: yup.string().when('vimeoOption', (vimeoOption) => {
            if (!vimeoOption)
              return yup
                .number()
                .typeError('Você deve preencher o campo apenas com números.')
                .required('Este campo é obrigatório!');
          }),
          file: yup.mixed().when('vimeoOption', (vimeoOption) => {
            if (vimeoOption)
              return yup.mixed().required('Este campo é obrigatório!');
          })
        })
      }),
      image: yup.object().when('type', {
        is: 'image',
        then: yup.object().shape({
          file: yup
            .mixed()
            .required('Este campo é obrigatório!')
            .test(
              'imageResolution',
              'A resolução da imagem é muito pequena! Por favor, carregue uma imagem de pelo menos 640x360.',
              function(value) {
                if (!value) {
                  return true; // Se o valor não estiver presente, a validação passa (opcional)
                }

                return new Promise((resolve) => {
                  if (!value) {
                    resolve(true); // Se o valor não estiver presente, a validação passa (opcional)
                  } else {
                    const img = new Image();
                    img.src = URL.createObjectURL(value);
                    img.onload = () => {
                      if (img.width < 640 || img.height < 360) {
                        resolve(false); // Falha na validação se a resolução for muito pequena
                      } else {
                        resolve(true); // A validação passa se a resolução estiver correta
                      }
                    };
                  }
                });
              }
            )
        })
      })
    })
  })
  .required();
