import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import { Box, Radio, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

export default function Alternative({
  alternative,
  checked,
  checkAlternative,
  submitted,
  chosenId,
  correctId,
  showCorrectAnswer
}) {
  const { text, id } = alternative;
  const [editorID, setEditorID] = useState(uniqid());

  const theme = useTheme();
  const classes = useStyles({
    theme,
    submitted,
    greenOutline:
      showCorrectAnswer ||
      (submitted && chosenId === correctId && id === chosenId),
    redOutline: submitted && chosenId !== correctId && id === chosenId,
    greenFilled: submitted && chosenId !== correctId && id === correctId
  });

  useEffect(() => {
    Editor.create(
      document.querySelector(`#document-editor__editable-${editorID}`)
    )
      .then((instance) => {
        instance.enableReadOnlyMode('editor');
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function check() {
    checkAlternative(id);
  }
  if (!alternative) return null;

  return (
    <Box className={classes.root}>
      <Radio
        disabled={submitted}
        className={classes.radio}
        disableRipple
        color="default"
        checkedIcon={
          <CheckCircleIcon className={`${classes.icon} ${classes.checked}`} />
        }
        icon={<RadioButtonUncheckedIcon className={classes.icon} />}
        checked={
          showCorrectAnswer
            ? true
            : !submitted
            ? checked
            : id === chosenId || id === correctId
        }
        onChange={check}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontSize: '12px'
        }}
      >
        <div
          id={`document-editor__editable-${editorID}`}
          className={classes.imgData}
          dangerouslySetInnerHTML={{
            __html: `<div>${text}</div>`
          }}
        />
      </Box>
    </Box>
  );
}

Alternative.propTypes = {
  alternative: PropTypes.shape(),
  checked: PropTypes.bool,
  checkAlternative: PropTypes.func,
  submitted: PropTypes.bool,
  chosenId: PropTypes.number,
  correctId: PropTypes.number
};

Alternative.defaultProps = {
  alternative: {},
  checked: false,
  checkAlternative: null,
  submitted: false,
  chosenId: -1,
  correctId: -1
};
