import React from 'react';
import { useSelector } from 'react-redux';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import CertificateService from '~/services/Certificate';
import { store } from '~/store';

import { useValidateCertificateContext } from '../ValidateCertificateContext';
import { useStyles } from './styles';
import { Box, useTheme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function DownloadButton() {
  const theme = useTheme();
  const classes = useStyles();
  const { certificate, isValid } = useValidateCertificateContext();
  const { isLogged } = store.getState().auth;
  const { profile } = useSelector((state) => state.user);

  const handleDownload = async () => {
    const blob = await CertificateService.getCertificate(certificate.id);
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = blobUrl;
    link.target = '_blank';
    link.download = `certificado_${certificate.structure.name}.pdf`;

    link.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  return (
    isLogged &&
    isValid &&
    certificate?.user?.id === profile?.id && (
      <Box>
        <ButtonSquare
          width={200}
          height={35}
          backgroundColor={theme.palette.secondary.main}
          onClick={handleDownload}
        >
          <Box
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <GetAppIcon style={{ color: 'white' }} />
            <Text className={classes.buttonText}>Baixar certificado</Text>
          </Box>
        </ButtonSquare>
      </Box>
    )
  );
}
