import React from 'react';

import { useStyles } from './styles';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import {
  DateTimePicker as MaterialPicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

export default function DateTimePickerInput(props) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <MaterialPicker
          {...props}
          autoOk
          className={classes.picker}
          inputVariant="outlined"
          format="dd/MM/yyyy H:mm"
          ampm={false}
          InputProps={{
            endAdornment: <EventIcon className={classes.icon} />
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

DateTimePickerInput.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string,
};

DateTimePickerInput.defaultProps = {
  input: {},
  label: '',
};
