import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';
import { Box, Radio, useTheme } from '@material-ui/core';
import Editor from 'ckeditor5-custom-viitra-build/build/ckeditor';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

export default function Alternative({
  alternative,
}) {
  const { text } = alternative;
  const [editorID, setEditorID] = useState(uniqid());

  const theme = useTheme();
  const classes = useStyles({
    theme,
  });

  useEffect(() => {
    Editor.create(
      document.querySelector(`#document-editor__editable-${editorID}`)
    )
      .then((instance) => {
        instance.enableReadOnlyMode('editor');
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box className={classes.root}>
      <Radio
        disabled
        className={classes.radio}
        disableRipple
        color="default"
        checked={false}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontSize: '12px'
        }}
      >
        <div
          id={`document-editor__editable-${editorID}`}
          className={classes.imgData}
          dangerouslySetInnerHTML={{
            __html: `<div>${text}</div>`
          }}
        />
      </Box>
    </Box>
  );
}

Alternative.propTypes = {
  alternative: PropTypes.shape(),
};

Alternative.defaultProps = {
  alternative: {},
};
 