import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  verifyButtonText: {
    color: 'white',
    fontSize: '13px'
  },
  cancelButtonText: {
    color: theme.palette.primary.main,
    fontSize: '13px'
  }
}));
