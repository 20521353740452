import React from 'react';

import NotificationInfo from '~/components/organisms/CourseForm/NotificationInfo';
import PagseguroInfo from '~/components/organisms/CourseForm/PagseguroInfo';
import { createCertificateConfiguration } from '~/services/course';

import CertificateConfiguration from '../../molecules/CertificateConfiguration';
import EnrollmentLimit from './EnrollmentLimit';
import EnrollmentLink from './EnrollmentLink';
import { useStyles } from './styles';
import { Box, Divider } from '@material-ui/core';

export default function CourseAdvancedOptions({ course }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <EnrollmentLink courseID={course.id} />
        <Divider className={classes.divider} />
      </Box>

      <Box>
        <EnrollmentLimit course={course} />
        <Divider className={classes.divider} />
      </Box>

      <Box>
        <CertificateConfiguration
          structure={course}
          createCertificateConfiguration={createCertificateConfiguration}
        />
        <Divider className={classes.divider} />
      </Box>

      {/*
      <Box marginBottom={2}>
        <PagseguroInfo />
        <Divider className={classes.divider} />
      </Box>

      <Box marginBottom={2}>
        <NotificationInfo />
        <Divider className={classes.divider} />
      </Box> */}
    </Box>
  );
}
