import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CertificateService from '~/services/Certificate';

import PropTypes from 'prop-types';

export const ValidateCertificateContext = createContext();
export const ValidateCertificateProvider = ({ children }) => {
  const { code } = useParams();

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [certificate, setCertificate] = useState(null);

  const reset = () => {
    setIsValid(false);
    setCertificate(null);
  };

  const handleVerify = async () => {
    try {
      setLoading(true);
      const data = await CertificateService.validateCode(code);
      setCertificate(data);
      setIsValid(true);
    } catch (error) {
      reset();
    }
    setLoading(false);
  };

  useEffect(() => {
    handleVerify();
  }, []);

  return (
    <ValidateCertificateContext.Provider
      value={{
        code,
        isValid,
        handleVerify,
        certificate,
        loading
      }}
    >
      {children}
    </ValidateCertificateContext.Provider>
  );
};

export const useValidateCertificateContext = () =>
  useContext(ValidateCertificateContext);

ValidateCertificateProvider.propTypes = {
  children: PropTypes.node.isRequired
};
