import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  picker: {
    width: '100%',
    '& input': {
      fontSize: 12,
      padding: 12
    },
  },
  label: {
    marginTop: 10,
    marginBottom: 5,
  },
  icon: {
    marginRight: -12,
    fontSize: 24,
  },
});
