import React from 'react';

import EnrollmentLinkForm from '~/components/molecules/EnrollmentLinkForm';
import i18n from '~/I18n';
import { createEnrollmentLink, getEnrollmentLink } from '~/services/course';
import enrollmentLinkService from '~/services/enrollment-link';

import { useStyles } from './styles';
import { Typography } from '@material-ui/core';

export default function EnrollmentLink({ courseID }) {
  const classes = useStyles();

  const tooltipEnrollmentLinkMessage = `Se este campo for preenchido, apenas membros de posse
  dessa chave conseguirão se inscrever no
  ${i18n.t(`StructuresName.Course.SingularUpper`)}
  através do link.`;

  return (
    <>
      <Typography className={classes.title} variant="h6" gutterBottom>
        Configurações de link de inscrição
      </Typography>
      <EnrollmentLinkForm
        structureID={courseID}
        tooltipMessage={tooltipEnrollmentLinkMessage}
        get={getEnrollmentLink}
        create={createEnrollmentLink}
        enrollmentLinkService={enrollmentLinkService}
      />
    </>
  );
}
