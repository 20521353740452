import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: '90%',
    maxHeight: '96%',
    height: 'auto',
    borderRadius: '8px',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    }
  },
  modalTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  dataConfigurationTitle: {
    fontSize: 20,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  dataConfiguration: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 17,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  infoIcon: {
    color: theme.palette.primary.main,
    fontSize: 25,
    fontWeight: 'medium'
  },
  workloadFlag: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-start'
  },
  flagIcon: {
    fontSize: 25
  },
  workloadText: {
    marginLeft: theme.spacing(0.5),
    fontSize: 15,
    fontWeight: '500'
  },
  textField: {
    marginBottom: theme.spacing(1.5)
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column'
  },
  uploadIcon: {
    marginBottom: theme.spacing(1)
  },
  uploadImage: {
    marginBottom: theme.spacing(2)
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  alertBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2)
  },
  alertIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 20
  },
  alertText: {
    flex: 1,
    fontSize: 13
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.textInactiveElement.main
    }
  },
  saveButton: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  fileInput: {
    display: 'none'
  }
}));
