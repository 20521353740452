import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0',
    border: '0',
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px'
  },
  icon: {
    marginLeft: '5px',
    fontSize: '24px',
    verticalAlign: 'middle'
  },
  customText: {
    padding: '0px',
    fontSize: '16px',
    fontWeight: '600',
    border: '8px solid #707070',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '6px',
    color: '#fff'
  },
  popoverBackground: {
    backgroundColor: '#707070',
    fontSize: '14px',
    margin: '0'
  },
  popoverText: {
    padding: '0px',
    fontFamily: 'Helvetica',
    color: '#F6F6F6',
    border: '10px solid #707070',
    margin: '6px',
    fontSize: '14px'
  },
  submitButton: {
    display: 'flex',
    marginLeft: 8,
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    fontSize: 12,
    fontWeight: 500
  },
  closeButton: {
    color: '#FFFFFF',
    cursor: 'pointer',
    fontSize: '18px'
  }
}));

export default useStyles;