// Imports for making HTTP requests and handling URL parameters.
import api from '~/services/api';
import { buildUrlWithParams } from '~/utils/buildUrlWithParams';

// Sets the base URL for interacting with the spaces API.
const baseURL = '/api/spaces';

// Retrieves specific space information by ID.
export const findByID = async (spaceID) => {
  const { data } = await api.get(`${baseURL}/${spaceID}`);
  return data;
};

// Retrieves paginated list of lectures associated with a space.
export const getLectures = async (spaceID, page, filters = {}) => {
  const { ord = '', direction = '', name_like = '' } = filters;
  const { data } = await api.get(
    `${baseURL}/${spaceID}/lectures?order=${ord}&sort=${direction}&name_like=${name_like}&page=${page}`
  );
  return data;
};

// Retrieves paginated list of subjects associated with a space.
export const getSubjects = async (spaceID, page, perPage) => {
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${spaceID}/subjects`, {
      page,
      per_page: perPage
    })
  );
  return data;
};

export const getAllSubjects = async (spaceID) => {
  const maxSubjectsCount = 2 ** 32 - 1;
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${spaceID}/subjects`, {
      per_page: maxSubjectsCount
    })
  );
  return data;
};

// Retrieves paginated list of enrollments associated with a space.
export const getEnrollments = async (spaceID, page, perPage = 18) => {
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${spaceID}/user_space_associations`, {
      page,
      per_page: perPage
    })
  );
  return data;
};

// Fetches the enrollment link for a specific space
export const getEnrollmentLink = async (spaceID) => {
  const { data } = await api.get(`${baseURL}/${spaceID}/enrollment_links`);
  return data;
};

// Creates a new enrollment link for a space
export const createEnrollmentLink = async (spaceID, body) => {
  const { data } = await api.post(`${baseURL}/${spaceID}/enrollment_links`, {
    enrollment_link: { ...body }
  });
  return data;
};

// Retrieves paginated list of statuses associated with a space.
export const getStatuses = async (spaceID, page) => {
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${spaceID}/statuses`, { page })
  );
  return data;
};

// Deletes a space by its ID.
export const remove = async (spaceID) => {
  const { data } = await api.delete(`${baseURL}/${spaceID}`);
  return data;
};

// Retrieves paginated list of members associated with a space.
export const getMembers = async (spaceID, page, perPage = 18) => {
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${spaceID}/user_space_associations`, {
      page,
      per_page: perPage
    })
  );
  return data;
};

// Searches for members within a space using a search query.
export const searchMembers = async (
  spaceID,
  searchString,
  page,
  perPage = 18
) => {
  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/${spaceID}/user_space_associations/search`, {
      q: searchString,
      page,
      per_page: perPage
    })
  );
  return data;
};

// Deletes specific members from a space.
export const deleteMembers = async (spaceID, membersID) => {
  await api.delete(`${baseURL}/${spaceID}/user_space_associations`, {
    data: { collection_ids: membersID }
  });
};

// Imports members to a space from a file.
export const importMembersFromFile = async (spaceID, fileData) => {
  await api.post(`${baseURL}/${spaceID}/import_data/import_members`, fileData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`
    }
  });
};

// Removes members from a space using file data.
export const removeMembersFromFile = async (spaceID, fileData) => {
  await api.post(`${baseURL}/${spaceID}/import_data/remove_members`, fileData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`
    }
  });
};

// Checks a user's association with a specific space.
export const checkAssociation = async (spaceID, userID) => {
  const { data } = await api.get(
    `/api/users/${userID}/spaces/${spaceID}/user_space_association`
  );
  return data;
};

// Updates roles of specific members within a space.
export const updateRoles = async (spaceID, membersID, role) => {
  await api.put(`${baseURL}/${spaceID}/user_space_associations`, {
    collection_ids: membersID,
    role
  });
};

export const createUser = async (spaceID, userData) => {
  const { data } = await api.post(`${baseURL}/${spaceID}/users`, userData);
  return data;
};
export const createCertificateConfiguration = async (spaceID, body) => {
  const { data } = await api.post(
    `${baseURL}/${spaceID}/certificate_configurations`,
    body
  );

  return data;
};

// Groups all space-related functionalities for export.
export default {
  findByID,
  getLectures,
  getSubjects,
  getEnrollments,
  getStatuses,
  remove,
  getMembers,
  searchMembers,
  deleteMembers,
  importMembersFromFile,
  removeMembersFromFile,
  checkAssociation,
  updateRoles,
  createUser,
  createCertificateConfiguration
};
