import React, { useState } from 'react';

import {
  ButtonBase,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  useTheme
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';

export default function MoreOptionsMenu ({
  setVisualizationMode,
  rotateValue,
  setScale,
  pageDimensions,
  setIsFullScreen
}) {
  const theme = useTheme();

  const [isOpenMoreOptionsMenu, setIsOpenMoreOptionsMenu] = useState(false);

  const handleClickOptionInMoreOptionsMenu = (SelectedVisualizationMode) => {
    setVisualizationMode(SelectedVisualizationMode);
    setIsOpenMoreOptionsMenu(false);
  };

  const adjustScaleToScreenSizeInPresentation = (width, height) => {
    const screenWidth = window?.screen?.width;
    const screenHeight = window?.screen?.height - 16;

    let scaleX;
    let scaleY;

    if (rotateValue === 90 || rotateValue === 270) {
      scaleX = screenHeight / width;
      scaleY = screenWidth / height;
    } else {
      scaleX = screenWidth / width;
      scaleY = screenHeight / height;
    }

    const scaleToUse = Math.min(scaleX, scaleY);

    setScale(scaleToUse);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpenMoreOptionsMenu(false)}>
      <span>
        <ButtonBase
          style={{ borderRadius: '4px' }}
          onClick={() => setIsOpenMoreOptionsMenu(true)}
        >
          <MoreVertIcon
            style={{
              height: '24px',
              width: '24px'
            }}
          />
        </ButtonBase>
        {isOpenMoreOptionsMenu && (
          <Paper
            style={{
              position: 'absolute',
              zIndex: 9999,
              top: '40px',
              right: '0px'
            }}
          >
            <MenuList>
              <MenuItem
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: theme.palette.textActiveElement.main
                }}
                onClick={() => handleClickOptionInMoreOptionsMenu('default')}
              >
                Visualizar em uma página
              </MenuItem>
              <MenuItem
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: theme.palette.textActiveElement.main
                }}
                onClick={() => handleClickOptionInMoreOptionsMenu('two-pages')}
              >
                Visualizar em duas páginas
              </MenuItem>
              <MenuItem
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: theme.palette.textActiveElement.main
                }}
                onClick={() => {
                  handleClickOptionInMoreOptionsMenu('presentation');
                  adjustScaleToScreenSizeInPresentation(
                    pageDimensions.width,
                    pageDimensions.height
                  );
                  setIsFullScreen(true);
                }}
              >
                Apresentação
              </MenuItem>
            </MenuList>
          </Paper>
        )}
      </span>
    </ClickAwayListener>
  );
}

MoreOptionsMenu.propTypes = {
  setVisualizationMode: PropTypes.func.isRequired,
  rotateValue: PropTypes.number.isRequired,
  setScale: PropTypes.func.isRequired,
  pageDimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  setIsFullScreen: PropTypes.func.isRequired
};
