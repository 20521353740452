import api from '~/services/api';

const baseURL = '/api/analytics/';

export const generateAnalyticsToken = async () => {
  const { data } = await api.get(`${baseURL}/users/analytics_link`);
  return data;
};

export default {
  generateAnalyticsToken
};
