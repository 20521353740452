import React from 'react';

import Text from '~/components/atoms/Text';
import settings from '~/settings';

import { useValidateCertificateContext } from '../ValidateCertificateContext';
import { Avatar, Box, useTheme } from '@material-ui/core';

export default function UserInfo() {
  const theme = useTheme();
  const { isValid, certificate, loading } = useValidateCertificateContext();
  return (
    !loading &&
    isValid && (
      <Box>
        <Text
          variant="xl"
          style={{
            letterSpacing: 1,
            fontWeight: 500,
            color: theme.palette.activeText.main
          }}
        >
          Informações do usuário
        </Text>

        <Box style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
          <Avatar
            style={{
              width: '64px',
              height: '64px',
              backgroundColor: '#F2F2F7',
              color: '#A8B1CE'
            }}
            src={`${settings.baseUrl}${certificate.user.avatar}`}
          />
          <Box>
            <Text
              variant="lg"
              style={{
                letterSpacing: 1,
                fontWeight: 500,
                color: theme.palette.text.main
              }}
            >
              {certificate.user.name}
            </Text>
            <Text
              variant="text"
              style={{
                letterSpacing: 1,
                fontWeight: 500,
                color: theme.palette.activeText.main
              }}
            >
              {certificate.user.email}
            </Text>
            <Text
              variant="text"
              style={{
                letterSpacing: 1,
                fontWeight: 500,
                color: theme.palette.activeText.main
              }}
            >
              {certificate.user.login}
            </Text>
          </Box>
        </Box>
      </Box>
    )
  );
}
