import React, { useEffect, useState } from 'react';

import i18n from '~/I18n';

import { useStyles } from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

export default function CertificateConfigBasicInfo({
  workload,
  certificateConfig
}) {
  const classes = useStyles();
  const [defaultValues, setDefaultValues] = useState({
    startDate: '00/00/00',
    endDate: '00/00/00',
    responsibleName: '--',
    responsiblePosition: '--',
    organizationName: '--',
    workload: workload,
    minimumPercentage: '--'
  });

  useEffect(() => {
    setDefaultValues({
      startDate: certificateConfig?.start_date
        ? format(parseISO(certificateConfig.start_date), 'dd/MM/yyyy')
        : '00/00/00',
      endDate: certificateConfig?.end_date
        ? format(parseISO(certificateConfig.end_date), 'dd/MM/yyyy')
        : '00/00/00',
      responsibleName: certificateConfig?.responsible_for_signing ?? '--',
      responsiblePosition: certificateConfig?.signature_owner_position ?? '--',
      organizationName: certificateConfig?.signature_owner_organization ?? '--',
      workload: workload,
      minimumPercentage: certificateConfig
        ? `${certificateConfig?.minimum_rate_for_completion}%`
        : '--'
    });
  }, [certificateConfig]);

  return (
    <Box className={classes.container}>
      <Box className={classes.period}>
        <Typography variant="h6" className={classes.header}>
          Período do {i18n.t('StructuresName.Course.SingularLower')}
        </Typography>
        <Grid container className={classes.dateField}>
          <Box item xs={6} container className={classes.dateContainer}>
            <EventIcon className={classes.dateIcon} />
            <Typography className={classes.dateText}>
              Início: {defaultValues.startDate}
            </Typography>
          </Box>
          <Box item xs={6} container className={classes.dateContainer}>
            <EventIcon className={classes.dateIcon} />
            <Typography className={classes.dateText}>
              Término: {defaultValues.endDate}
            </Typography>
          </Box>
        </Grid>
      </Box>

      <Box className={classes.infoBox}>
        <Grid container>
          <Grid>
            <Typography className={classes.headTitle}>
              Nome do Responsável:
            </Typography>
            <Typography className={classes.info}>
              {defaultValues.responsibleName}
            </Typography>
            <Typography className={classes.headTitle}>
              Nome da organização:
            </Typography>
            <Typography className={classes.info}>
              {defaultValues.organizationName}
            </Typography>
            <Typography className={classes.headTitle}>
              Carga horária:
            </Typography>
            <Typography className={classes.info}>
              {defaultValues.workload}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.secondBox}>
            <Typography className={classes.headTitle}>
              Cargo do Responsável:
            </Typography>
            <Typography className={classes.info}>
              {defaultValues.responsiblePosition}
            </Typography>
            <Box className={classes.percentageBox}>
              <Typography className={classes.headTitle}>
                Percentual mínimo para gerar certificado:
              </Typography>
              <Typography className={classes.info}>
                {defaultValues.minimumPercentage}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

CertificateConfigBasicInfo.propTypes = {
  workload: PropTypes.number.isRequired,
  certificateConfig: PropTypes.object
};
