import i18n from '~/I18n';
import Text from '~/components/atoms/Text';
import React from 'react';
import { parseISO, format } from 'date-fns';

export const calculateDate = (date) => {
  return new Date(new Date(date).getTime() + 1 * 24 * 60 * 60 * 1000);
};

export const formatStringToDate = (date, separator = '-') => {
  try {
    const splitDate = date.split(separator);
    const newDate = new Date(
      splitDate[0],
      splitDate[1] - 1,
      splitDate[2],
      0,
      0,
      0
    );
    return newDate;
  } catch (error) {
    return null;
  }
};

export const formatDateToDateZeroHour = (date, hour = 0) => {
  try {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      hour,
      0
    );
    return newDate;
  } catch (error) {
    return null;
  }
};

export const formatTime = (unformattedSeconds) => {
  const seconds = Math.round(unformattedSeconds);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const pad = (number) => (number < 10 ? '0' + number : number);

  let formattedTime = '';
  if (hours > 0) formattedTime += pad(hours) + ':';
  if (minutes > 0) formattedTime += pad(minutes) + ':';
  if (secs > 0 || formattedTime === '') formattedTime += pad(secs);

  return formattedTime;
};

export const dynamicTime = (seconds) => {
  if (seconds < 60) {
    return (
      <Text style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}>
        {i18n.t('Seconds')}
      </Text>
    );
  } else if (seconds < 3600) {
    return (
      <Text style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}>
        {i18n.t('Minutes')}
      </Text>
    );
  } else {
    return (
      <Text style={{ fontSize: '10px', marginTop: '-10px', color: '#a1a1a1' }}>
        {i18n.t('Hours')}
      </Text>
    );
  }
};

export const convertDateToLocal = (dateString) => {
  // Primeiro, converte a string de data para um objeto Date no fuso horário local
  const date = parseISO(dateString + 'T00:00:00');
  // Em seguida, formata de volta para uma string no formato desejado
  return format(date, 'yyyy/MM/dd');
}

export const formatLocalTime = (dateTime) => {
  const [datePart, timePart] = dateTime.split('T');

  const [year, month, day] = datePart.split('-');
  const formattedDate = `${day}/${month}/${year}`;

  const [hour, minute, second] = timePart.split('-')[0].split(':');
  const formattedTime = `${hour}:${minute}`;

  return [formattedDate, formattedTime];
};
