import React from 'react';
import { Switch, useLocation } from 'react-router-dom';

import About from '~/pages/About';
import PasswordReset from '~/pages/auth/PasswordReset';
import RecoverPassword from '~/pages/auth/RecoverPassword';
import Courses from '~/pages/Courses';
import CreateCourse from '~/pages/CreateCourse';
import CreateEnvironment from '~/pages/CreateEnvironment';
import CreateSpace from '~/pages/CreateSpace';
import CreateSubject from '~/pages/CreateSubject';
import Dashboard from '~/pages/dashboard';
import Environments from '~/pages/dashboard/Environments';
import Profile from '~/pages/dashboard/Profile';
import EditProfile from '~/pages/dashboard/Profile/EditProfile';
import EditCourse from '~/pages/EditCourse';
import EditEnvironment from '~/pages/EditEnvironment';
import EditSpace from '~/pages/EditSpace';
import EditSubject from '~/pages/EditSubject';
import Environment from '~/pages/Environment';
import { EditUser } from '~/pages/Environment/EditUser';
import GoogleOauth2Callback from '~/pages/GoogleOauth2Callback';
import Help from '~/pages/Help';
import Lecture from '~/pages/Lecture';
import Login from '~/pages/Login';
import { SebraeLoginRedirect } from '~/pages/Login/RightContent/OthersLogins/SebraeLogin/SebraeLoginRedirect';
import Messages from '~/pages/Messages';
import PageNotFound from '~/pages/PageNotFound';
import PagseguroRedirect from '~/pages/PagseguroRedirect';
import PrivacyPolicyAndUsageTerms from '~/pages/PrivacyPolicyAndUsageTerms';
import ReplicableProcesses from '~/pages/ReplicableProcesses';
import ReplicateCourse from '~/pages/Replication/course';
import ReplicateEnvironment from '~/pages/Replication/environment';
import ReplicateSpace from '~/pages/Replication/space';
import ReplicateSubject from '~/pages/Replication/subject';
import SebraeHandling from '~/pages/SebraeHandling';
import Space from '~/pages/Space';
import ValidateCertificate from '~/pages/ValidateCertificate';
import { hasAdminAccess } from '~/services/AccessControl/accessControl';
import {
  hasManageAccessToCourse,
  hasReadAccessToCourse,
  hasUpdateAccessToCourse
} from '~/services/AccessControl/courseAccessControl';
import {
  hasManageAccessToEnvironment,
  hasReadAccessToEnvironment,
  hasUpdateAccessToEnvironment
} from '~/services/AccessControl/environmentAccessControl';
import { hasReadAccessToLecture } from '~/services/AccessControl/lectureAccessControl';
import RouteAccessControlWrapper from '~/services/AccessControl/RouteAccessControlWrapper';
import {
  hasManageAccessToSpace,
  hasReadAccessToSpace,
  hasUpdateAccessToSpace
} from '~/services/AccessControl/spaceAccessControl';
import { hasUpdateAccessToSubject } from '~/services/AccessControl/subjectAccessControl';
import {
  hasManageAccessToUser,
  hasReadAccessToUser
} from '~/services/AccessControl/userAccessControl';

// Route Decorator
import Route from './Route';

const Routes = () => {
  let location = useLocation();
  let background = location.state && location.state.background;
  const isEditMember =
    window.location.hostname === 'avamecinterativo.mec.gov.br' ||
    window.location.hostname === 'beta.ava.viitra.in' ||
    window.location.hostname === 'localhost';

  return (
    <div>
      <Switch location={background || location}>
        <Route exact path="/login" component={Login} />
        <Route exact path="/sebrae-login" component={SebraeLoginRedirect} />
        <Route exact path="/recover_password" component={RecoverPassword} />
        <Route exact path="/password_reset/:token" component={PasswordReset} />
        <Route exact path="/about" component={About} alwaysAccess />
        <Route
          exact
          path={['/validate-certificate', '/validate-certificate/:code']}
          component={ValidateCertificate}
          alwaysAccess
        />
        <Route exact path="/dashboard" component={Dashboard} isPrivate />
        <Route
          exact
          path="/replicable_processes"
          component={ReplicableProcesses}
          isPrivate
        />
        <Route
          exact
          path="/sebrae-handling"
          component={SebraeHandling}
          isPrivate
        />

        {/* Environments */}
        <Route
          exact
          path="/dashboard/environments"
          component={Environments}
          isPrivate
        />
        <Route
          exact
          path="/dashboard/environments/new"
          function={hasAdminAccess}
          component={() =>
            RouteAccessControlWrapper(CreateEnvironment, hasAdminAccess)
          }
          isPrivate
        />
        <Route
          exact
          path="/dashboard/environments/new_mirror_environment"
          component={() =>
            RouteAccessControlWrapper(ReplicateEnvironment, hasAdminAccess)
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/environments/:id"
          component={() =>
            RouteAccessControlWrapper(
              Environment,
              hasReadAccessToEnvironment,
              'id'
            )
          }
          isPrivate
        />
        <Route
          exact
          path="/dashboard/environments/:id/edit"
          component={() =>
            RouteAccessControlWrapper(
              EditEnvironment,
              hasUpdateAccessToEnvironment,
              'id'
            )
          }
          isPrivate
        />

        {/* Courses */}
        <Route
          exact
          path="/dashboard/environments/:id/courses/new"
          component={() =>
            RouteAccessControlWrapper(
              CreateCourse,
              hasManageAccessToEnvironment,
              'id'
            )
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/environments/:environmentID/new_mirror_course"
          component={() =>
            RouteAccessControlWrapper(
              ReplicateCourse,
              hasManageAccessToEnvironment,
              'environmentID'
            )
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/environments/:id/courses/:courseId"
          component={() =>
            RouteAccessControlWrapper(
              Courses,
              hasReadAccessToCourse,
              'courseId'
            )
          }
          isPrivate
        />
        <Route
          exact
          path="/dashboard/environments/:environmentID/courses/:courseID/edit"
          component={() =>
            RouteAccessControlWrapper(
              EditCourse,
              hasUpdateAccessToCourse,
              'courseID'
            )
          }
          isPrivate
        />

        {/* Spaces */}
        <Route
          exact
          path="/dashboard/spaces/:id"
          component={() =>
            RouteAccessControlWrapper(Space, hasReadAccessToSpace, 'id')
          }
          isPrivate
        />
        <Route
          exact
          path="/dashboard/environments/:environmentID/courses/:courseID/spaces/new"
          component={() =>
            RouteAccessControlWrapper(
              CreateSpace,
              hasManageAccessToCourse,
              'courseID'
            )
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/courses/:courseID/new_mirror_space"
          component={() =>
            RouteAccessControlWrapper(
              ReplicateSpace,
              hasManageAccessToCourse,
              'courseID'
            )
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/environments/:environmentID/courses/:courseID/spaces/:spaceID/edit"
          component={() =>
            RouteAccessControlWrapper(
              EditSpace,
              hasUpdateAccessToSpace,
              'spaceID'
            )
          }
          isPrivate
        />

        {/* Subjects */}
        <Route
          exact
          path="/dashboard/environments/:environmentID/courses/:courseID/spaces/:spaceID/subjects/new"
          component={() =>
            RouteAccessControlWrapper(
              CreateSubject,
              hasManageAccessToSpace,
              'spaceID'
            )
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/spaces/:spaceID/new_mirror_subject"
          component={() =>
            RouteAccessControlWrapper(
              ReplicateSubject,
              hasManageAccessToSpace,
              'spaceID'
            )
          }
          isPrivate
        />

        <Route
          exact
          path="/dashboard/environments/:environmentID/courses/:courseID/spaces/:spaceID/subjects/:subjectID/edit"
          component={() =>
            RouteAccessControlWrapper(
              EditSubject,
              hasUpdateAccessToSubject,
              'subjectID'
            )
          }
          isPrivate
        />

        {/* Lectures */}
        <Route
          exact
          path="/dashboard/lecture/:id"
          component={() =>
            RouteAccessControlWrapper(Lecture, hasReadAccessToLecture, 'id')
          }
          isPrivate
        />

        {/* Profiles */}
        <Route
          exact
          path="/profile/:id"
          component={() =>
            RouteAccessControlWrapper(Profile, hasReadAccessToUser, 'id')
          }
          isPrivate
        />
        <Route
          exact
          path="/profile/:id/edit"
          component={() =>
            RouteAccessControlWrapper(EditProfile, hasManageAccessToUser, 'id')
          }
          isPrivate
        />

        <Route exact path="/help" component={Help} alwaysAccess />
        <Route path="/messages" component={Messages} isPrivate />
        <Route
          exact
          path="/pagseguro-redirect/:id"
          component={PagseguroRedirect}
          isPrivate
        />
        <Route
          exact
          path="/privacy-policy-and-usage-terms"
          component={PrivacyPolicyAndUsageTerms}
          alwaysAccess
        />

        <Route
          exact
          path="/google_oauth2/callback"
          component={GoogleOauth2Callback}
          alwaysAccess
        />

        <Route pageNotFound component={PageNotFound} />
      </Switch>
      {background && isEditMember && (
        <Route
          exact
          path="/dashboard/environments/:id/edit/members/:member_id"
          component={() =>
            RouteAccessControlWrapper(
              EditUser,
              hasUpdateAccessToEnvironment,
              'id'
            )
          }
          isPrivate
        />
      )}
    </div>
  );
};

export default Routes;
