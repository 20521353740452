import React from 'react';

import { useStyles } from './styles';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event'
import {
  KeyboardDatePicker as MaterialPicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

const DateTimePickerInput = (props) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MaterialPicker
        {...props}
        autoOk
        className={classes.picker}
        inputVariant="outlined"
        format="dd/MM/yyyy"
        ampm={false}
        keyboardIcon={<EventIcon className={classes.icon} />}
      />
    </MuiPickersUtilsProvider>
  );
};

DateTimePickerInput.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string
};

DateTimePickerInput.defaultProps = {
  input: {},
  label: ''
};

export default DateTimePickerInput;
