import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import Input from '~/components/atoms/TextInput';
import enrollmentLimitService from '~/services/enrollment-limit/index.js';

import useStyles from './styles.js';
import {
  Button,
  Box,
  Switch,
  FormControlLabel,
  Collapse
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function EnrollmentLimit({ course }) {
  const classes = useStyles();
  const { control, handleSubmit, watch, setValue } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const userLimitEnabled = watch('user_limit_enabled');
  const [idLimit, setIdLimit] = useState(course.id_limit);
  const [previousUserLimitEnabled, setPreviousUserLimitEnabled] = useState(
    course.user_limit_enabled
  );

  useEffect(() => {
    if (
      previousUserLimitEnabled === true &&
      userLimitEnabled === false &&
      idLimit
    ) {
      enrollmentLimitService.removeUserLimit(idLimit).then(() => {
        setValue('user_limit', null);
        setValue('user_limit_enabled', false);
        setIdLimit(null);
        enqueueSnackbar('Limite de inscrições removido com sucesso!', {
          variant: 'success'
        });
      });
    }
    console.log(previousUserLimitEnabled, userLimitEnabled);
    setPreviousUserLimitEnabled(userLimitEnabled);
  }, [userLimitEnabled]);

  const handleSuccess = () => {
    enqueueSnackbar('Limite de inscrições salvo com sucesso!', {
      variant: 'success'
    });
  };

  const handleError = () => {
    enqueueSnackbar('Erro ao salvar limite de inscrições.', {
      variant: 'error'
    });
  };

  const onSubmit = (data) => {
    const body = {
      enrollment_limit: {
        user_limit: data.user_limit
      }
    };

    if (!idLimit && userLimitEnabled) {
      enrollmentLimitService
        .createEnrollmentLimit(course.id, body)
        .then((response) => {
          setValue('user_limit_enabled', true);
          setIdLimit(response.id);
          handleSuccess();
        })
        .catch(handleError);
    } else {
      enrollmentLimitService
        .updateEnrollmentLimit(idLimit, body)
        .then(handleSuccess)
        .catch(handleError);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" alignItems="center" marginLeft={'12px'}>
        <Controller
          name="user_limit_enabled"
          control={control}
          defaultValue={course.user_limit_enabled}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Text color={'gray'} fontSize={'16px'}>
                  Definir Limite de Inscrições{' '}
                </Text>
              }
            />
          )}
        />
      </Box>

      <Collapse in={userLimitEnabled}>
        <Box display="flex" alignItems="center" marginRight={2}>
          <Text color={'gray'} fontSize={'16px'}>
            Limite de Inscrições
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Controller
            name="user_limit"
            control={control}
            defaultValue={course.user_limit}
            render={({ field }) => (
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <Input
                  placeholder="Ex: 35"
                  inputMode="numeric"
                  type="number"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  {...field}
                />
              </div>
            )}
          />
          <Button type="submit" className={classes.submitButton}>
            Salvar
          </Button>
        </Box>
      </Collapse>
    </form>
  );
}
