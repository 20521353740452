import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  viewButtonContainer: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&.Mui-disabled': {
      backgroundColor: 'transparent'
    },
    '&.Mui-disabled $viewButtonIcon, &.Mui-disabled $viewButtonText': {
      color: theme.palette.action.disabled
    }
  },
  viewButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginRight: '10px'
  },
  viewButtonText: {
    fontSize: 14,
    color: theme.palette.primary.main
  },
  generateButton: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  excludeButton: {
    marginRight: theme.spacing(1),
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.textInactiveElement.main
    }
  }
}));
