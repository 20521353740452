import React, { useEffect, useState } from 'react';

import CertificateFormModal from '~/components/Modals/CertificateForm';
import PreViewCertificate from '~/components/Modals/PreViewCertificate';
import CertificateService from '~/services/Certificate';

import CertificateConfigBasicInfo from './CertificateConfigBasicInfo';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export default function CertificateConfiguration({
  structure,
  createCertificateConfiguration
}) {
  const classes = useStyles();
  const [certificateConfig, setCertificateConfig] = useState();
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openCertificateConfigForm, setOpenCertificateConfigForm] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (structure.certificate_configuration_id) {
      CertificateService.getCertificateConfiguration(
        structure.certificate_configuration_id
      ).then((certificateConfig) => {
        setCertificateConfig(certificateConfig);
      });
    }
  }, [structure.certificate_configuration_id]);

  const handleViewCertificate = () => {
    setOpenViewModal(true);
  };

  const handleSuccess = (data) => {
    enqueueSnackbar(`Certificado excluído com sucesso!`, {
      variant: 'success'
    });
    setCertificateConfig(data);
  };

  const handleFailure = (data) => {
    enqueueSnackbar(`Erro ao excluir certificado.`, {
      variant: 'error'
    });
  };

  const handleDelete = () => {
    CertificateService.deleteCertificateConfig(certificateConfig.id)
      .then(handleSuccess)
      .catch(handleFailure);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title} variant="h6" gutterBottom>
          Configurações de Certificado
        </Typography>
        <Button
          className={classes.viewButtonContainer}
          onClick={handleViewCertificate}
          disabled={!certificateConfig}
        >
          <Visibility className={classes.viewButtonIcon} />

          <Typography className={classes.viewButtonText} variant="h6">
            Visualizar certificado
          </Typography>
        </Button>

        <PreViewCertificate
          openPreview={openViewModal}
          setOpenPreview={setOpenViewModal}
          openEditButton={openCertificateConfigForm}
          setOpenEditButton={setOpenCertificateConfigForm}
          configurationID={certificateConfig?.id}
        />
      </Box>
      <CertificateConfigBasicInfo
        certificateConfig={certificateConfig}
        workload={structure.workload}
      />
      <Box display="flex" justifyContent="flex-end">
        {certificateConfig && (
          <Button onClick={handleDelete} className={classes.excludeButton}>
            Excluir
          </Button>
        )}
        <Button
          onClick={() => setOpenCertificateConfigForm(true)}
          className={classes.generateButton}
          disabled={structure.workload === 0 && !certificateConfig}
        >
          {certificateConfig ? 'Editar Certificado' : 'Criar Certificado'}
        </Button>
      </Box>
      {openCertificateConfigForm && (
        <CertificateFormModal
          open={openCertificateConfigForm}
          onClose={() => setOpenCertificateConfigForm(false)}
          certificateConfig={certificateConfig}
          setCertificateConfig={setCertificateConfig}
          workload={structure.workload}
          ID={structure.id}
          createCertificateConfiguration={createCertificateConfiguration}
        />
      )}
    </Box>
  );
}

CertificateConfiguration.propTypes = {
  structure: PropTypes.object.isRequired,
  createCertificateConfiguration: PropTypes.func.isRequired
};
