import api from '~/services/api';

const configurationBaseURL = '/api/certificate_configurations/';
const courseBaseURL = '/api/courses/';
const certificateBaseURL = '/api/certificates/';

export const getCertificateConfiguration = async (certificateConfigID) => {
  const { data } = await api.get(configurationBaseURL + certificateConfigID);

  return data;
};

export const deleteCertificateConfig = async (certificateID) => {
  const { data } = await api.delete(configurationBaseURL + `${certificateID}`);

  return data;
};

export const updateCertificateConfiguration = async (
  certificateConfigID,
  body
) => {
  const { data } = await api.put(
    configurationBaseURL + `${certificateConfigID}`,
    body
  );

  return data;
};

export const createCertificate = async (certificateConfigID) => {
  const { data } = await api.post(
    `${configurationBaseURL}${certificateConfigID}/generate_certificate`
  );

  return data;
};

export const getCertificate = async (certificateID) => {
  const { data } = await api.get(`${certificateBaseURL}${certificateID}`, {
    responseType: 'blob'
  });

  return data;
};

export const previewCertificate = async (configurationID) => {
  const { data } = await api.get(
    `${configurationBaseURL}${configurationID}/preview_certificate`,
    {
      responseType: 'blob'
    }
  );

  return data;
};

export const validateCode = async (code) => {
  const { data } = await api.get(`${certificateBaseURL}validate/${code}`);

  return data;
};

export default {
  getCertificateConfiguration,
  deleteCertificateConfig,
  updateCertificateConfiguration,
  createCertificate,
  getCertificate,
  previewCertificate,
  validateCode
};
