import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '70%',
    height: '100%',
    maxHeight: '100%',
    padding: '0 5px 10px 0',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1300px)': {
      width: '100%'
    }
  },
  divider: {
    margin: `30px 0px 20px 0px`,
    backgroundColor: theme.palette.divider
  }
}));
