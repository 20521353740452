import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
    width: '100vw'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
    paddingTop: '60px'
  },
  sidebar: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  tabBar: {
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'block'
    }
  },
  sidebarContent: {
    paddingTop: 180
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    [theme.breakpoints.down('lg')]: {
      width: '65%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0px 16px'
    }
  },
  infosHeader: {
    marginTop: '34px',
    marginBottom: '5px',
    maxWidth: '100%',
    '& p': {
      maxWidth: '100%'
    }
  },
  infoIcon: {
    color: (props) => `${props.theme.palette.accent.main}`,
    marginRight: '8px'
  },
  ambientInformationText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  infosContent: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxWidth: '100%',
    marginTop: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& ::-webkit-scrollbar': {
      width: '5px'
    },
    '& ::-webkit-scrollbar-track': {
      background: 'none'
    },
    '& ::-webkit-scrollbar-thumb': {
      background: (props) => `${props.theme.palette.secondary.main}`,
      bordeRadius: '2px'
    }
  },
  materialsDesktop: {
    width: '25%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shimmerItem: {
    marginBottom: 24,
    borderRadius: 5,
    width: 230,
    height: 130,
    '@media (max-width: 600px)': {
      width: 315,
      height: 176
    }
  },
  certificateInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  certificateButton: {
    height: '38px',
    textTransform: 'none',
    fontSize: '14px',
    marginLeft: '5px',
    color: 'white'
  }
}));

export const ShimmerText = styled(
  ({ color, height, width, marginTop, ...otherProps }) => (
    <Skeleton
      {...otherProps}
      height={height}
      animation="pulse"
      width={width}
      style={{
        backgroundColor: (props) => `${props.theme.palette.shimmer.main}`,
        marginTop: `${marginTop}px`
      }}
    />
  )
)``;
