import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  icon: {
    marginLeft: '5px',
    fontSize: '20px',
    verticalAlign: 'middle'
  },
  button: {
    display: 'flex',
    marginLeft: 8,
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    color: 'white',
    fontSize: 12,
    fontWeight: 500
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: 'white'
    }
  },
  removeButton: {
    color: theme.palette.primary.main,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.textInactiveElement.main
    },
    '&:active': {
      backgroundColor: 'white'
    },
    '&:disabled': {
      backgroundColor: '#B4BDD3',
      color: 'white'
    }
  }
}));

export default useStyles;
